import React from 'react';
import PropType from 'prop-types';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

import Layout from '../components/layout';


const PostTemplate = (props) => {
  const { data: { wordpressPost: post } } = props;

  return (
    <Layout id="wrapper">
      <Helmet
        title={post.title}
        meta={[
          { name: 'description', content: post.excerpt },
        ]}
        bodyAttributes={{
          class: 'blog blog-single post '+post.slug
        }}
      />

      <article className="inner container">
        <header className="inner">

          <div className="background-bar">
            { post.featured_media && post.featured_media.localFile && post.featured_media.localFile.childImageSharp && (
            <Img
              src={post.featured_media.localFile.childImageSharp.sizes.src}
              sizes={post.featured_media.localFile.childImageSharp.sizes}
              className="img-fluid"
              alt={post.title}
            />
            )}

          </div>
          <div>
              <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
            </div>
        </header>
        <section className="inner container-fluid main-body">
          <section className="row">
            <div className="hidden-xs col-sm-1 col-md-2" />
            <div className="col-xs-12 col-sm-10 col-md-8">
              <div className="content-holder">
                <div className="content-description">
                  <div className="row blog-info">
                    <div className="col-xs-12 col-sm-6">
                      <span className="lead text-muted">
                        <i className="fa fa-clock-o" />
                        {' '}
                          Published:
                        {' '}
                        {post.date}
                      </span>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <span className="lead text-muted">
                        <i className="fa fa-tags" />
                        {post.categories && post.categories.map(category => category.name)}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="content-body" dangerouslySetInnerHTML={{ __html: post.content }} />
              </div>
            </div>
            <div className="hidden-xs col-sm-1 col-md-2" />
          </section>
        </section>
      </article>
    </Layout>
  );
};
PostTemplate.propTypes = {
  data: PropType.shape({}).isRequired,
};
export default PostTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
        title
        content
        excerpt
        date(formatString: "DD, MMM YYYY")
        categories {
            id
            name
        }
        author{
            name
            description
            avatar_urls{
              wordpress_48
            }
        }
        featured_media{
          localFile{
            childImageSharp{
              id
              sizes( maxWidth: 800 ) {
                  ...GatsbyImageSharpSizes
              }
            }
          }
        }
        slug
    }
  }
`;